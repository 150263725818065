<script>
import Chart from "chart.js";
import { generateChart } from "vue-chartjs";
import moment from "moment";
Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function (ease) {
    Chart.controllers.line.prototype.draw.call(this, ease);

    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      let activePoint = this.chart.tooltip._active[0];
      let ctx = this.chart.ctx;
      let x = activePoint.tooltipPosition().x;
      let topY = this.chart.scales["y-axis-0"].top;
      let bottomY = this.chart.scales["y-axis-0"].bottom;

      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 6;
      ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
      ctx.stroke();
      ctx.restore();
    }
  },
});

const Bar = generateChart("Bar", "bar");
export default {
  extends: Bar,
  props: {
    tickFormat: {
      type: String,
      default: "DD MMM",
    },
    data: {
      type: Array,
      default: null,
    },
    options: {
      type: Object,
    },
    color: {
      type: String,
      default: "rgba(156, 106, 222, 0.8)",
    },
    dataLabels: {
      type: Array,
      default: () => {
        return ["Ganancia"];
      },
    },
    labels: {
      type: Array,
    },
    currency: {
      type: String,
      default: () => {
        return "ars";
      },
    },
  },
  computed: {
    c_options() {
      var self = this;
      return Object.assign(
        {
          legend: {
            display: false,
          },
          fill: false,
          responsive: true,
          maintainAspectRatio: true,
          barThickness: "flex",
          barPercentage: 1,
          hover: {
            mode: "nearest",
            intersect: false,
          },
          tooltips: {
            mode: "nearest",
            xAlign: "center",
            xPadding: 9,
            yPadding: 9,
            _bodyAlign: "center",
            _titleAlign: "center",
            intersect: false,
            callbacks: {
              title: function (item) {
                var tmp = moment(item[0].xLabel, self.tickFormat);
                if (self.tickFormat == "HH[hs]")
                  return tmp.format("HH[hs] [del] dddd");
                return tmp.format("DD [de] MMM, YYYY");
              },
              label: function (item) {
                if (self.currency) {
                  return new Intl.NumberFormat("es", {
                    style: "currency",
                    currency: self.currency,
                  }).format(item.yLabel);
                } else return item.yLabel || "Sin Datos";
              },
            },
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  lineWidth: 0.5,
                },
                ticks: {
                  display: true,
                },
              },
            ],
            xAxes: [
              {
                autoSkipPadding: 20,
                autoSkip: true,
                gridLines: {
                  borderDash: [5],
                  lineWidth: 0.5,
                },
                barPercentage: 1.0,
                categoryPercentage: 0.7,
                ticks: {
                  maxRotation: 0,
                  callback: function (value) {
                    return value;
                    // return value.format(self.tickFormat);
                  },
                },
              },
            ],
          },
        },
        this.options
      );
    },
    fresh_data() {
      return this.data;
    },
    fresh_labels() {
      return this.labels;
    },
  },
  watch: {
    data() {
      this.render();
    },
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: this.fresh_labels,
          datasets: [
            {
              label: "Ganancia",
              lineTension: 0,
              backgroundColor: this.color,
              borderColor: this.color,
              data: this.fresh_data,
              borderWidth: 2,
              pointRadius: 0,
              pointHoverRadius: 3,
              pointHitRadius: 3,
              fill: false,
            },
          ],
        },
        this.c_options
      );
    },
  },
};
</script>

<style></style>
